







import {defineComponent, PropType} from "@nuxtjs/composition-api";
import {ContentConfigsInterface} from "../../../types";

export default defineComponent({
  name: 'ColumnLine',
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
  },
  setup(props) {
    const {
      display
    }: ContentConfigsInterface = props.data;

    const dynamicStyles: ContentConfigsInterface = {
      display
    }

    return {
      dynamicStyles
    }
  }
});
