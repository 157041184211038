import {defineAsyncComponent} from "@nuxtjs/composition-api";
import Row from "../components/content-types/row/Row.vue";
import rowConfigAggregator from "../components/content-types/row/configAggregator";
import ColumnGroup from "../components/content-types/column-group/ColumnGroup.vue";
import columnGroupConfigAggregator from "../components/content-types/column-group/configAggregator";
import ColumnLine from "../components/content-types/column-line/ColumnLine.vue";
import columnLineConfigAggregator from "../components/content-types/column-line/configAggregator";
import Column from "../components/content-types/column/Column.vue";
import columnConfigAggregator from "../components/content-types/column/configAggregator";
import textConfigAggregator from "../components/content-types/text/configAggregator";
import headingConfigAggregator from "../components/content-types/heading/configAggregator";
import sliderConfigAggregator from "../components/content-types/slider/configAggregator";
import bannerConfigAggregator from "../components/content-types/banner/configAggregator";
import popularCategoriesConfigAggregator from "../components/content-types/popular-categories/configAggregator";
import productsConfigAggregator from "../components/content-types/products/configAggregator";
import newsletterConfigAggregator from "../components/content-types/newsletter/configAggregator";
import blockConfigAggregator from "../components/content-types/block/configAggregator";
import htmlConfigAggregator from "../components/content-types/html/configAggregator";


const contentTypesConfig = {
    row: {
        configAggregator: rowConfigAggregator,
        component: Row
    },
    'column-group': {
        configAggregator: columnGroupConfigAggregator,
        component: ColumnGroup
    },
    'column-line': {
        configAggregator: columnLineConfigAggregator,
        component: ColumnLine
    },
    column: {
        configAggregator: columnConfigAggregator,
        component: Column
    },
    image: {
        configAggregator: {},
        component: null,
        componentShimmer: null
    },
    heading: {
        configAggregator: headingConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/heading/Heading.vue"))
    },
    text: {
        configAggregator: textConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/text/Text.vue"))
    },
    tabs: {
        configAggregator: {},
        component: null
    },
    'tab-item': {
        configAggregator: {},
        component: null
    },
    buttons: {
        configAggregator: {},
        component: null
    },
    'button-item': {
        configAggregator: {},
        component: null
    },
    block: {
        configAggregator: blockConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/block/Block.vue")),
    },
    dynamic_block: {
        configAggregator: {},
        component: null,
        componentShimmer: null
    },
    products: {
        configAggregator: productsConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/products/Products.vue")),
    },
    popular_categories: {
        configAggregator: popularCategoriesConfigAggregator,
        component: defineAsyncComponent(() => import("~/modules/gomage/plugin-popular-categories/components/PopularCategories.vue")),
    },
    newsletter: {
        configAggregator: newsletterConfigAggregator,
        component: defineAsyncComponent(() => import("~/modules/gomage/plugin-newsletter/Components/NewsLetter.vue")),
    },
    html: {
        configAggregator: htmlConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/html/Html.vue")),
    },
    divider: {
        configAggregator: {},
        component: null
    },
    video: {
        configAggregator: {},
        component: null
    },
    map: {
        configAggregator: {},
        component: null
    },
    banner: {
        configAggregator: bannerConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/banner/Banner.vue")),
        componentShimmer: null
    },
    slider: {
        configAggregator: sliderConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/slider/Slider.vue")),
        componentShimmer: null
    },
    // Slide is just a banner wrapped inside a slider
    slide: {
        configAggregator: bannerConfigAggregator,
        component: defineAsyncComponent(() => import("../components/content-types/banner/Banner.vue")),
        componentShimmer: null
    }
};

/**
 * Retrieve a content types configuration
 */
export function getContentTypeConfig(contentType: string) {
    if (contentTypesConfig[contentType]) {
        return contentTypesConfig[contentType];
    }
}

/**
 * Set content types configuration with new one
 */
export function setContentTypeConfig(contentType: string, config) {
    return (contentTypesConfig[contentType] = config);
}
