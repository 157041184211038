










import {defineComponent, computed, PropType} from "@nuxtjs/composition-api";
import { getContentTypeConfig } from '../helpers/contentTypeConfigurator';
import {ContentConfigsInterface} from "../types";

export default defineComponent({
  name: "ContentTypeFactory",
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
  },
  setup(props) {
    const { isHidden, ...restProps }: ContentConfigsInterface = props.data;

    if (isHidden) {
      return null;
    }

    const contentTypeConfig = computed(() =>
      getContentTypeConfig(restProps.contentType)
    );

    const component = computed(() => {
      if (contentTypeConfig.value && contentTypeConfig.value.component) {
        return contentTypeConfig.value.component
      }

      return null;
    });

    return {
      component,
      isHidden,
      restProps,
    };
  },
});
