var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appearance === 'full-bleed' || _vm.appearance === 'full-width')?_c('div',{ref:"backgroundElement",class:['fullBleed', 'root' ].concat( _vm.cssClasses),style:(Object.assign({}, _vm.dynamicStyles,
    _vm.bgImageStyle,
    _vm.mediaQueryStyles,
    {marginLeft: null,
    marginRight: null,
    '--pbRowMarginLeft': _vm.marginLeft,
    '--pbRowMarginRight': _vm.marginRight}))},[(_vm.videoOverlayColor)?_c('div',{staticClass:"videoOverlay",style:(_vm.videoOverlayStyles)}):_vm._e(),_vm._v(" "),(_vm.appearance === 'full-width')?_c('div',{staticClass:"contained"},[_vm._t("default")],2):_vm._t("default")],2):_c('div',{class:['contained' ].concat( _vm.cssClasses)},[_c('div',{ref:"backgroundElement",staticClass:"inner",style:(Object.assign({}, _vm.dynamicStyles,
      _vm.bgImageStyle,
      _vm.mediaQueryStyles))},[(_vm.videoOverlayColor)?_c('div',{staticClass:"videoOverlay",style:(_vm.videoOverlayStyles)}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }