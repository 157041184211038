import { getContentTypeConfig } from '../helpers/contentTypeConfigurator';
import {ContentTypeInterface} from "../types";

/**
 * Create a basic object representing a content type in our tree
 */
const createContentTypeObject = (type: string, node: Element | null): ContentTypeInterface => {
    return {
        contentType: type,
        appearance: node ? node.getAttribute('data-appearance') : null,
        children: []
    };
};

/**
 * Walk over tree nodes extracting each content types configuration
 */
const walk = (rootEl: HTMLElement, contentTypeStructureObj: ContentTypeInterface) => {
    const tree = document.createTreeWalker(rootEl, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT);

    let currentNode = tree.nextNode() as HTMLElement;
    while (currentNode) {
        if (currentNode.nodeType !== Node.ELEMENT_NODE) {
            currentNode = tree.nextNode() as HTMLElement;
            continue;
        }

        const { contentType } = currentNode.dataset;

        if (!contentType) {
            currentNode = tree.nextNode() as HTMLElement;
            continue;
        }

        const props = createContentTypeObject(contentType, currentNode);
        const contentTypeConfig = getContentTypeConfig(contentType);

        if (
            contentTypeConfig &&
            typeof contentTypeConfig.configAggregator === 'function'
        ) {
            try {
                Object.assign(
                    props,
                    contentTypeConfig.configAggregator(currentNode, props)
                );
            } catch (e) {
                console.error(
                    `Failed to aggregate config for content type ${contentType}.`,
                    e
                );
            }
        } else {
            console.warn(
                `Page Builder ${contentType} content type is not supported, this content will not be rendered.`
            );
        }

        contentTypeStructureObj.children.push(props);
        walk(currentNode, props);
        currentNode = tree.nextSibling() as HTMLElement;
    }

    return contentTypeStructureObj;
};

const pbStyleAttribute = 'data-pb-style';
const bodyId = 'html-body';

export const convertToInlineStyles = (document: Document) => {
    const styleBlocks = document.querySelectorAll('style');
    const styles = {};
    if (styleBlocks.length > 0) {
        Array.from(styleBlocks).forEach((styleBlock) => {
            const { cssRules } = styleBlock.sheet;

            Array.from(cssRules).forEach((rule) => {
                if (rule instanceof CSSStyleRule) {
                    const selectors = rule.selectorText
                      .split(',')
                      .map((selector) => selector.trim());

                    selectors.forEach((selector) => {
                        if (!styles[selector]) styles[selector] = [];
                        styles[selector].push(rule.style);
                    });
                }
            });
        });
    }

    Object.keys(styles).forEach((selector) => {
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }

        styles[selector].forEach((style) => {
            element.setAttribute(
              'style',
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/restrict-plus-operands
              element.style.cssText + style.cssText,
            );
        });
        element.removeAttribute(pbStyleAttribute);
    });
};

/**
 * Parse the master format storage HTML
 */
const parseStorageHtml = htmlStr => {
    const container = new DOMParser().parseFromString(htmlStr, 'text/html');

    const stageContentType: ContentTypeInterface = createContentTypeObject('root-container', null);

    container.body.id = bodyId;
    convertToInlineStyles(container);

    return walk(container.body, stageContentType);
};

export default parseStorageHtml;
