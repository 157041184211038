














































import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
  defineComponent,
  PropType,
  onUnmounted
} from "@nuxtjs/composition-api";
import {ContentConfigsInterface} from "../../../types";
import useMediaQuery from "../../../composables/useMediaQuery/useMediaQuery";
import resourceUrl from "../../../helpers/url/makeUrl";
import { verticalAlignmentToFlex } from "../../../helpers/utils";

export default defineComponent({
  name: 'Row',
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
  },
  setup(props) {
    const {
      appearance,
      backgroundAttachment,
      backgroundColor,
      backgroundSize,
      backgroundPosition,
      backgroundRepeat,
      backgroundType,
      border,
      borderColor,
      borderWidth,
      borderRadius,
      cssClasses = [],
      desktopImage,
      enableParallax,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      mediaQueries,
      minHeight,
      mobileImage,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      parallaxSpeed = 0.5,
      textAlign,
      videoSrc,
      verticalAlignment,
      videoFallbackSrc,
      videoLoop,
      videoPlayOnlyVisible,
      videoLazyLoading,
      videoOverlayColor
    }: ContentConfigsInterface = props.data;

    const backgroundElement = ref(null);
    const bgImageStyle = ref<string|null>(null);
    let jarallaxInstance = null;

    const { matchMedia } = globalThis;

    const image = computed(() => {
      if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
        return mobileImage;
      }
      return desktopImage;
    });

    // Todo: needs to test is the received data correct. I dont know how fill mediaQueries variable
    const mediaQueryStyles = useMediaQuery({mediaQueries: mediaQueries});

    const videoOverlayStyles = {
      backgroundColor: videoOverlayColor
    };

    const dynamicStyles: ContentConfigsInterface = {
      minHeight,
      backgroundColor,
      textAlign,
      border,
      borderColor,
      borderWidth,
      borderRadius,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    };

    if (image) {
      dynamicStyles.backgroundSize = backgroundSize;
      dynamicStyles.backgroundPosition = backgroundPosition;
      dynamicStyles.backgroundAttachment = backgroundAttachment;
      dynamicStyles.backgroundRepeat = backgroundRepeat;
    }

    if (verticalAlignment) {
      dynamicStyles.display = 'flex';
      dynamicStyles.justifyContent = verticalAlignmentToFlex(
        verticalAlignment
      );
      dynamicStyles.flexDirection = 'column';
    }

    const addBgImageStyle = () => {
      if (backgroundSize === 'cover') {
        let elementWidth = backgroundElement.value.offsetWidth;
        let elementHeight = backgroundElement.value.offsetHeight;
        // If parallax is enabled resize at a higher resolution, as the image will be zoomed
        if (enableParallax) {
          elementWidth = Math.round(elementWidth * 1.25);
          elementHeight = Math.round(elementHeight * 1.25);
        }

        bgImageStyle.value = `url(${resourceUrl(image.value, {
          type: 'image-wysiwyg',
          width: elementWidth,
          height: elementHeight,
          quality: 85,
          crop: false,
          fit: 'cover'
        })})`
      } else {
        bgImageStyle.value = `url(${resourceUrl(image.value, {
          type: 'image-wysiwyg',
          quality: 85
        })})`;
      }
    }

    const addParallaxBackground = () => {
      const parallaxElement = backgroundElement.value;
      let jarallax;
      let jarallaxVideo;

      if (enableParallax && bgImageStyle && backgroundType !== 'video') {
        setTimeout(() => {
          ({jarallax: jarallaxInstance} = require('jarallax'));
          jarallaxInstance(parallaxElement, {
            speed: parallaxSpeed,
            imgSize: backgroundSize,
            imgPosition: backgroundPosition,
            imgRepeat: backgroundRepeat
          });
        });
      }

      if (backgroundType === 'video') {
        ({jarallax: jarallaxInstance} = require('jarallax'));
        ({ jarallaxVideo } = require('jarallax'));
        jarallaxVideo();
        jarallaxInstance(parallaxElement, {
          speed: enableParallax ? parallaxSpeed : 1,
          imgSrc: videoFallbackSrc
            ? resourceUrl(videoFallbackSrc, {
              type: 'image-wysiwyg',
              quality: 85
            })
            : null,
          videoSrc,
          videoLoop,
          videoPlayOnlyVisible,
          videoLazyLoading,
          zIndex: 'auto'
        });

        parallaxElement.jarallax.video &&
        parallaxElement.jarallax.video.on('started', () => {
          const self = parallaxElement.jarallax;

          // show video
          if (self.$video) {
            self.$video.style.visibility = 'visible';
          }
        });
      }
    }

    onMounted(() => {
      if (image.value && backgroundElement.value) {
        addBgImageStyle();
      }

      if(backgroundElement.value) {
        addParallaxBackground();
      }
    });

    onUnmounted(() => {
      if (jarallaxInstance) {
        jarallaxInstance(backgroundElement.value, 'destroy')
      }
    });

    return {
      appearance,
      backgroundElement,
      backgroundType,
      bgImageStyle,
      cssClasses,
      dynamicStyles,
      enableParallax,
      marginLeft,
      marginRight,
      mediaQueryStyles,
      videoOverlayColor,
      videoOverlayStyles
    }
  },
  watch: {
    bgImageStyle() {
      if(this.bgImageStyle) {
        this.dynamicStyles.backgroundImage = this.bgImageStyle;
      }
    },

  }
});
