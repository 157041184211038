














import {computed, defineComponent, onMounted, PropType, ref} from "@nuxtjs/composition-api";
import {ContentConfigsInterface} from "../../../types";
import useMediaQuery from "../../../composables/useMediaQuery/useMediaQuery";
import resourceUrl from "../../../helpers/url/makeUrl";

export default defineComponent({
  name: 'Column',
  props: {
    data: {
      type: Object as PropType<ContentConfigsInterface>,
      required: true,
    },
  },
  setup(props) {
    const {
      appearance,
      backgroundAttachment,
      backgroundColor,
      backgroundPosition,
      backgroundRepeat = 'repeat',
      backgroundSize,
      border,
      borderColor,
      borderRadius,
      borderWidth,
      cssClasses = [],
      desktopImage,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      mediaQueries,
      minHeight,
      mobileImage,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      textAlign,
      verticalAlignment,
      width
    }: ContentConfigsInterface = props.data;

    const backgroundElement = ref(null);
    const bgImageStyle = ref<string|null>(null);

    const { matchMedia } = globalThis;

    const image = computed(() => {
      if (mobileImage && matchMedia && matchMedia('(max-width: 768px)').matches) {
        return mobileImage;
      }
      return desktopImage;
    });

    // Todo: needs to test is the received data correct. I dont know how fill mediaQueries variable
    const mediaQueryStyles = useMediaQuery({mediaQueries: mediaQueries});


    const dynamicStyles: ContentConfigsInterface = {
      backgroundColor,
      border,
      borderColor,
      borderRadius,
      borderWidth,
      display: 'flex',
      flexDirection: 'column',
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      minHeight,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      textAlign,
      verticalAlignment,
      width
    }

    switch (appearance) {
      case 'align-top':
        dynamicStyles.alignSelf = 'flex-start';
        break;
      case 'align-center':
        dynamicStyles.alignSelf = 'center';
        break;
      case 'align-bottom':
        dynamicStyles.alignSelf = 'flex-end';
        break;
      case 'full-height':
      default:
        dynamicStyles.alignSelf = 'stretch';
        break;
    }

    switch (verticalAlignment) {
      case 'middle':
        dynamicStyles.justifyContent = 'center';
        break;
      case 'bottom':
        dynamicStyles.justifyContent = 'flex-end';
        break;
      case 'top':
      default:
        dynamicStyles.justifyContent = 'flex-start';
        break;
    }

    if (image) {
      dynamicStyles.backgroundSize = backgroundSize;
      dynamicStyles.backgroundPosition = backgroundPosition;
      dynamicStyles.backgroundAttachment = backgroundAttachment;
      dynamicStyles.backgroundRepeat = backgroundRepeat;
    }


    onMounted(() => {
      if (image.value && backgroundElement.value) {
        if (backgroundSize === 'cover') {
          bgImageStyle.value = `url(${resourceUrl(image.value, {
            type: 'image-wysiwyg',
            width: backgroundElement.value.offsetWidth,
            height: backgroundElement.value.offsetHeight,
            quality: 85,
            crop: false,
            fit: 'cover'
          })})`
        } else {
          bgImageStyle.value = `url(${resourceUrl(image.value, {
            type: 'image-wysiwyg',
            quality: 85
          })})`;
        }
      }
    });

    return {
      bgImageStyle,
      cssClasses,
      dynamicStyles,
      mediaQueryStyles
    }
  },
  watch: {
    bgImageStyle() {
      if(this.bgImageStyle) {
        this.dynamicStyles.backgroundImage = this.bgImageStyle;
      }
    },

  },
});
