import { ref, watchEffect } from '@vue/composition-api';

interface MediaQueryRule {
  media: string;
  style: { [key: string]: string };
}

const useMediaQuery = (props: { mediaQueries: MediaQueryRule[] }) => {
  const styles = ref<{ [key: string]: string }>({});

  const { mediaQueries } = props;

  const handleMatch = (query: MediaQueryList, i: number) => {
    if (query.matches) {
      styles.value = { ...styles.value, ...mediaQueries[i].style };
    } else {
      styles.value = Object.keys(styles.value).reduce((obj, key) => {
        if (mediaQueries[i].style[key] !== styles.value[key]) {
          obj[key] = styles.value[key];
        }
        return obj;
      }, {});
    }
  };

  watchEffect(() => {
    const mqlList = mediaQueries.map(({ media }) => matchMedia(media));

    mqlList.forEach((mql, i) => {
      if (mql.matches) {
        styles.value = { ...styles.value, ...mediaQueries[i].style };
      }

      const listener = (query: MediaQueryListEvent) => handleMatch(query.currentTarget as MediaQueryList, i);
      mql.addListener(listener);

      return () => mql.removeListener(listener);
    });
  });

  return styles.value;
};

export default useMediaQuery;