










import {defineComponent, onMounted, ref} from "@nuxtjs/composition-api";
import parseStorageHtml from "../helpers/parseStorageHtml";
import ContentTypeFactory from "../components/ContentTypeFactory.vue";
import {ContentTypeInterface} from "../types";

export default defineComponent({
  name: 'PageBuilder',
  components: {
    ContentTypeFactory
  },
  props: {
    content: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const parsedContent = ref<ContentTypeInterface | null>(null);

    onMounted(() => {
      parsedContent.value = parseStorageHtml(props.content);
    });

    return {
      parsedContent
    }
  }
})
